// Please keep the colors in this list in sync with
// the corresponding values in _variables.sccs file.

// This file exists because we receive color names, rather
// than the color values from the back end, and we can't use scss variables
// in inline styling.
export default {
  white: '#ffffff',
  red: '#e64545',
  darkblue: '#2549fc',
  lightblue: '#45d3e6',
  black: '#363636',
  yellow: '#e6c545',
};
