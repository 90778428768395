import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

Vue.use(Router);

const supportedGames = store.state.gamesMapper;

const router = new Router({
  mode: process.env.VUE_APP_DESKTOP_BUILD === 'true' ? 'hash' : 'history',
  routes: Object.keys(supportedGames).map((key) => {
    const route = {
      path: `/${key}`,
      component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
      props: {
        game: supportedGames[key],
      },
    };
    return route;
  }),
});

export default router;
