import { defaultTo, each } from 'lodash';
import { getApiRoute } from '@nsftx/games-visualization-sdk/config';

export default {
  rawEvent: state => state.event,
  config: state => state.config,
  urlParams: state => state.urlParams,
  rules: state => state.rules,
  product: state => state.product,
  loader: state => state.loader,
  isLoaderActive: state => state.loader.isLoaderActive,
  eventInfo: state => state.eventInfo,
  nextEventInfo: state => state.nextEventInfo,
  initializedGame: state => state.initializedGame,
  busServiceInitialized: state => state.busServiceInitialized,
  // stats route returns a list
  statistics: state => state.statistics[0],
  activeGame: state => state.activeGame,
  isOnline: state => state.isOnline,
  isLongerOffline: state => state.connectionLostDuration > state.connectionOfflineLimit,
  connectionLostDuration: state => state.connectionLostDuration,
  isDesktopApp: state => state.isDesktopApp,
  desktopSystem: state => state.desktopSystem,
  translations: state => state.translations,
  translate: state => key => state.translations[key] || key,
  language: state => state.language,
  currentEvent: state => state.event.currentEvent,
  offer: state => state.event.offer,
  allEvents: state => state.event.events,
  results: state => state.event.results,
  previousResults: state => state.event.previousResults,
  jackpotInfoAmount: state => state.event.jackpotInfoAmount,
  jackpotWonData: state => state.event.jackpotWonData,
  jackpotWonFinished: state => state.event.jackpotWonFinished,
  linodeEncodedWebStorage: state => state.linodeEncodedWebStorage,
  countdownTime: state => state.event.countdownTime,
  countdownDelay: state => state.event.countdownDelay,
  countdownEventData: state => state.countdownEventData,
  shouldDropHeaderBeforeCountdown: state => state.shouldDropHeaderBeforeCountdown,
  shouldRotateEvents: state => state.shouldRotateEvents,
  countdownPhaseDuration: state => state.countdownPhaseDuration,
  mobileStatisticsPhaseDuration: state => state.mobileStatisticsPhaseDuration,
  countdownAnimationDuration: state => state.countdownAnimationDuration,
  statisticsAnimationDuration: state => state.statisticsAnimationDuration,
  statisticsPhaseDuration: state => state.statisticsPhaseDuration,
  isSingleWordHalftimeLanguage: state => ['sr', 'sr-Latn'].includes(state.config.locale),
  goals: (state, getters) => {
    if (getters.results?.goals) {
      return getters.results.goals.map((goal, idx, goals) => {
        const parsedGoal = {
          half: goal.half,
          score: `${goal.homeGoals}-${goal.awayGoals}`,
        };
        if (idx <= 0) {
          parsedGoal.side = goal.homeGoals ? 'home' : 'away';
        } else {
          parsedGoal.side = goals[idx - 1].homeGoals < goals[idx].homeGoals ? 'home' : 'away';
        }

        return parsedGoal;
      });
    }
    return [];
  },
  hasChartAnimationEnded: state => state.hasChartAnimationEnded,
  eventId: state => state.event.eventId,
  video: state => state.event.video,
  videoGoalList: state => state.event.videoGoalList,
  videoResult: state => state.event.videoResult,
  videoFinalResult: state => state.event.videoFinalResult,
  videoFinished: state => state.event.videoFinished,
  allVideos: state => state.event.allVideos,
  videoId: state => state.event.videoId,
  videoExt: state => state.event.videoExt,
  stateData: state => state.event.stateData,
  stateMode: state => state.event.stateMode,
  stopBettingDuration: state => state.stopBettingDuration,
  resultsDuration: state => state.resultsDuration,
  displaySettings: state => state.displaySettings,
  isHalfTime: state => state.isHalfTime,
  halfTimeDuration: state => state.halfTimeDuration,
  windowWidth: state => state.windowWidth,
  /**
   * Calculates the hold time for each of the statistics components
   * before the next one is shown, on small screen sizes
   */
  statisticsDurationDistribution: (state, getters) => {
    // Both the fowards and reverse animations of a statistics chart last 2250ms,
    // which is 1500ms for the curve animation + 750ms for the rest.
    const duration = getters.isLargeResolution
      ? getters.statisticsPhaseDuration
      : getters.mobileStatisticsPhaseDuration;
    const remainingTime = duration * 1000 - 2 * 4500 - 3000;
    // Next, we will distribute the remaining time in a ratio of 2:1.
    const chartsHoldTime = Math.round((remainingTime * 2) / 3);
    const mutualMatchesHoldTime = remainingTime - chartsHoldTime;

    return {
      charts: chartsHoldTime,
      mutualMatches: mutualMatchesHoldTime,
    };
  },
  chartHoldDuration: (state, getters) => getters.statisticsDurationDistribution.charts,
  mutualMatchesHoldDuration: (_, getters) => getters.statisticsDurationDistribution.mutualMatches,
  currentBreakpoint: (state, getters) => {
    const { windowWidth: width } = getters;
    const breakpoint = getters.breakpoints.find(({ maxWidth }) => width < maxWidth);
    return breakpoint ? breakpoint.size : 'large';
  },
  breakpoints: state => state.breakpoints,
  isLargeResolution: (state, getters) => getters.currentBreakpoint === 'large',
  getAssetPath: (state, getters) => (asset, forceLocal) => {
    const { displaySettings } = state;
    const localAssets = getters['sdk/localAssets'];
    let assetsPath = '';
    if (getters.isDesktopApp && !displaySettings.forceExternalAssets) {
      assetsPath = localAssets.absolutePath;
    } else if (!getters.isDesktopApp) {
      assetsPath = getters.linodeEncodedWebStorage;
    } else {
      const hostname = displaySettings.devAssets
      || forceLocal ? '/assets/' : getApiRoute('assetsStorage');
      assetsPath = `${hostname}${displaySettings.assetsFolder}/`;
    }
    return `${assetsPath}${asset}`;
  },
  getTranslation:
    (state, getters, rootState, rootGetters) => (key, params = []) => {
      let trans = defaultTo(state.translations[key], rootGetters['sdk/getTranslation'](key, params));

      each(params, (value) => {
        trans = trans.replace(/{{[a-zA-Z0-9-_/]*}}/g, value);
      });

      return trans;
    },
  jackpotActive: state => state.jackpotActive,
  numberFormat: state => state.numberFormat,
  assetsSubsetReady: state => state.assetsSubsetReady,
  assetsReady: (state, getters) => (
    (getters.isDesktopApp && getters.assetsSubsetReady && getters['sdk/localAssetsReady'])
      || (!getters.isDesktopApp && getters.assetsSubsetReady)
  ),
  isConnectionAlive: state => state.connectionAlive,
  isGameResponsive: state => state.gameResponsive,
  healthCheckerTime: state => state.healthCheckerTime,
  defaultHealthCheckerTime: state => state.defaultHealthCheckerTime,
  audioOn: state => state.audioOn,
};
