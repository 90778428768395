const reorderArray = (array, from, to) => {
  // Delete the item from it's current position
  const item = array.splice(from, 1);
  // Move the item to its new position
  array.splice(to, 0, item[0]);

  return array;
};

export default reorderArray;
