export default (data, video, index, goalList) => {
  const homeResult = video.competitors[0].fullTime;
  const awayResult = video.competitors[1].fullTime;
  if (index <= 0) {
    if (homeResult === 1) {
      goalList.home.push({
        name: video.competitors[0].name,
        result: `${homeResult}:${awayResult}`,
      });
    } else if (awayResult === 1) {
      goalList.away.push({
        name: video.competitors[1].name,
        result: `${homeResult}:${awayResult}`,
      });
    }
  } else {
    const lastHomeResult = data[index - 1].competitors[0].fullTime;
    const lastAwayResult = data[index - 1].competitors[1].fullTime;
    if (homeResult !== lastHomeResult) {
      goalList.home.push({
        name: video.competitors[0].name,
        result: `${homeResult}:${awayResult}`,
      });
    } else if (awayResult !== lastAwayResult) {
      goalList.away.push({
        name: video.competitors[1].name,
        result: `${homeResult}:${awayResult}`,
      });
    }
  }
  return { homeResult, awayResult, goalList };
};
