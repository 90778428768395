import anime from 'animejs';
import { waitForPromiseResolution } from '@/utility/helpers';
import { reverseKeySplinesString, createCubicBezierString } from '@/utility/bezier/helpers';

const curveAnimationDuration = 1500;
const keySplines = '.35 .41 .31 .97';
const statisticsAnimationEasing = createCubicBezierString(keySplines);
const superbonusFadeDuration = 750;

const animateCountdown = (dir) => {
  const countdownCardsEasing = createCubicBezierString('0 0 0 1');
  const countdownTimeline = anime.timeline({
    duration: 920,
    easing: countdownCardsEasing,
    direction: dir,
  });
  countdownTimeline
    .add(
      {
        targets: '.countdown-card-content',
        translateY: [50, 0],
        delay: anime.stagger(120),
        duration: 920,
      },
      0,
    ).add({
      targets: '.countdown-card-content',
      opacity: [0, 1],
      delay: anime.stagger(120),
      duration: 480,
    }, 0)
    .add(
      {
        targets: '.previous-results-content',
        translateY: [50, 0],
        opacity: [0, 1],
      },
      250,
    );
};


const reverseAnimateCountdown = () => {
  const countdownCardsReverseEasing = createCubicBezierString(reverseKeySplinesString('0 0 0 1'));
  const countdownTimeline = anime.timeline({
    duration: 920,
    easing: countdownCardsReverseEasing,
  });
  countdownTimeline
    .add(
      {
        targets: '.countdown-card-content',
        translateY: [0, 50],
        delay: anime.stagger(120),
      },
      0,
    ).add({
      targets: '.countdown-card-content',
      opacity: [1, 0],
      delay: anime.stagger(120),
      duration: 480,
    }, 0)
    .add(
      {
        targets: '.previous-results-content',
        translateY: [0, 50],
        opacity: [1, 0],
      },
      250,
    );
};

const animatePreviousResults = () => {
  const countdownCardsReverseEasing = createCubicBezierString(reverseKeySplinesString('0 0 0 1'));
  const countdownTimeline = anime.timeline({
    duration: 920,
    easing: countdownCardsReverseEasing,
  });
  countdownTimeline.add(
    {
      targets: '.previous-results-content',
      translateY: [50, 0],
      opacity: [0, 1],
    },
  );
};

const animateCountdownBar = (value) => {
  anime({
    targets: '.loader-progress-bar-time.active',
    width: 0,
    duration: value * 1000,
    loop: 1,
    easing: 'linear',
  });
};

const animateSuperbonusInfo = (dir) => {
  anime({
    targets: '.superbonus-info-content',
    translateY: [-150, 0],
    opacity: [0, 1],
    duration: 750,
    easing: 'easeInOutQuad',
    direction: dir,
  });
};

const animateSuperbonus = (dir) => {
  anime({
    targets: '.superbonus-content',
    translateY: [50, 0],
    opacity: [0, 1],
    duration: 750,
    easing: 'linear',
    direction: dir,
  });
};

const animateSuperbonusHistory = dir => animateSuperbonus(dir);

const animateMatchResult = (dir) => {
  const matchResultTimeline = anime.timeline({
    easing: 'linear',
    direction: dir,
  });
  matchResultTimeline
    .add(
      {
        targets: '.match-result-content .result-wrap',
        opacity: [0, 1],
        duration: 100,
      },
      0,
    )
    .add(
      {
        targets: '.match-result-content .team-wrap-small.home',
        translateX: ['50%', 0],
        scaleX: [0, 1],
        duration: 500,
      },
      100,
    )
    .add(
      {
        targets: '.match-result-content .team-wrap-small.away',
        translateX: ['-50%', 0],
        scaleX: [0, 1],
        duration: 500,
      },
      100,
    )
    .add(
      {
        targets: '.match-result-content p',
        opacity: [0, 1],
        duration: 100,
      },
      600,
    );
};

const animateMatchCurrentStat = (dir) => {
  const matchCurrentStatTimeline = anime.timeline({
    easing: 'easeInOutQuad',
    direction: dir,
  });
  matchCurrentStatTimeline
    .add(
      {
        targets: '.match-current-stat-content .current-stat-wrap',
        translateY: [50, 0],
        opacity: [0, 1],
        duration: 250,
      },
      0,
    )
    .add(
      {
        targets: '.match-current-stat-content .team-wrap.home',
        translateX: ['50%', 0],
        scaleX: [0, 1],
        duration: 500,
      },
      250,
    )
    .add(
      {
        targets: '.match-current-stat-content .name-wrap.home',
        translateX: ['50%', 0],
        scaleX: [0, 1],
        duration: 500,
      },
      250,
    )
    .add(
      {
        targets: '.match-current-stat-content .team-wrap.away',
        translateX: ['-50%', 0],
        scaleX: [0, 1],
        duration: 500,
      },
      250,
    )
    .add(
      {
        targets: '.match-current-stat-content .name-wrap.away',
        translateX: ['-50%', 0],
        scaleX: [0, 1],
        duration: 500,
      },
      250,
    )
    .add(
      {
        targets: '.match-current-stat-content .shirt-wrap',
        opacity: [0, 1],
        duration: 500,
      },
      750,
    )
    .add(
      {
        targets: '.match-current-stat-content p',
        opacity: [0, 1],
        duration: 500,
      },
      750,
    );
};

const animateMatchHighlights = (dir) => {
  const matchHighlightsTimeline = anime.timeline({
    easing: 'linear',
    delay: 1250,
    direction: dir,
  });
  matchHighlightsTimeline
    .add(
      {
        targets: '.match-highlights',
        height: [0, '60vh'],
        opacity: [0, 1],
        duration: 500,
      },
      0,
    )
    .add(
      {
        targets: '.match-highlights-content',
        opacity: [0, 1],
        duration: 500,
      },
      500,
    );
};

const animateResults = (dir) => {
  const resultsTimeline = anime.timeline({
    duration: 750,
    easing: 'easeInOutQuad',
    direction: dir,
  });
  resultsTimeline
    .add(
      {
        targets: '.competitor-shirt-home, .competitor-info-home',
        translateX: [-50, 0],
        opacity: [0, 1],
      },
      0,
    )
    .add(
      {
        targets: '.competitor-shirt-away, .competitor-info-away',
        translateX: [50, 0],
        opacity: [0, 1],
      },
      0,
    )
    .add(
      {
        targets: '.results-details-content',
        translateY: [50, 0],
        opacity: [0, 1],
      },
      0,
    )
    .add(
      {
        targets: '.results-middle-space',
        opacity: [0, 1],
      },
      0,
    );
};

const animateTeamShirt = (side = 'home', direction) => anime({
  targets: `.competitor-shirt-${side}, .competitor-info-${side}`,
  easing: statisticsAnimationEasing,
  translateX: [-50, 0],
  opacity: [0, 1],
  direction,
});

const animateStatistics = (direction) => {
  const statisticsTimeline = anime.timeline({
    duration: curveAnimationDuration / 2,
    easing: 'easeInOutQuad',
    direction,
  });
  statisticsTimeline
    .add(
      {
        targets: '.competitor-shirt-home, .competitor-info-home',
        translateX: [-50, 0],
        opacity: [0, 1],
      },
      0,
    )
    .add(
      {
        targets: '.competitor-shirt-away, .competitor-info-away',
        translateX: [50, 0],
        opacity: [0, 1],
      },
      0,
    )
    .add(
      {
        targets: '.statistics-details-content',
        translateY: [50, 0],
        opacity: [0, 1],
      },
      0,
    )
    .add(
      {
        targets: '.statistics-middle-space',
        opacity: [0, 1],
      },
      0,
    );
  return waitForPromiseResolution(statisticsTimeline.finished);
};

const animateMutualMatches = (dir) => {
  const duration = curveAnimationDuration / 2;
  const mutualMatchesTimeline = anime.timeline({
    easing: statisticsAnimationEasing,
    duration,
    direction: dir,
  });

  mutualMatchesTimeline.add(
    {
      targets: '.mutual-matches',
      translateY: [50, 0],
      opacity: [0, 1],
    },
    0,
  ).add(
    {
      targets: '.mutual-matches__header',
      color: ['transparent', '#1F1F1F'],
    },
    duration,
  ).add(
    {
      targets: '.mutual-matches__overview-digit, .mutual-matches__goals-per-match__goals',
      color: ['transparent', '#E6C545'],
    },
    duration,
  ).add(
    {
      targets: '.mutual-matches__past-results',
      color: ['transparent', '#FFFFFF'],
    },
    duration,
  )
    .add(
      {
        targets: '.mutual-matches__overview-side, .mutual-matches__goals-per-match__text',
        color: ['transparent', '#B3B3B3'],
      },
      duration,
    );

  return waitForPromiseResolution(mutualMatchesTimeline.finished);
};

const animateChart = ({
  id, curve, isLargeResolution = true, dir,
}, store) => {
  const offset = isLargeResolution ? 750 : 0;
  const curveLength = curve.getTotalLength();
  const statisticsTimeline = anime.timeline({ direction: dir });
  const curvePath = anime.path(`.curvePath--${id}`);

  if (dir !== 'reverse') {
    curve.setAttribute('stroke-dasharray', curveLength);
    curve.setAttribute('stroke-dashoffset', curveLength);
  }


  if (isLargeResolution && dir === 'reverse') {
    statisticsTimeline.add(
      {
        targets: '.wrapper',
        opacity: [0, 1],
        easing: statisticsAnimationEasing,

      },
      0,
    );
  } else if (!isLargeResolution && dir === 'reverse') {
    statisticsTimeline.add(
      {
        targets: '.rectangles, .chart-wrapper',
        opacity: [0, 1],
        easing: statisticsAnimationEasing,
      },
      0,
    );
  } else {
    if (isLargeResolution) {
      statisticsTimeline
        .add(
          {
            targets: '.wrapper',
            translateY: [50, 0],
            opacity: [0, 1],
          },
          0,
        )
        .add(
          {
            targets: '.rectangles',
            easing: statisticsAnimationEasing,
            'background-color': ['transparent', 'rgba(0, 0, 0, 0.1)'],
          },
          offset + curveAnimationDuration,
        );
    }

    statisticsTimeline
      .add(
        {
          targets: `.statistics__side--${id} .curvePath`,
          'stroke-dashoffset': [curveLength, 0],
          duration: curveAnimationDuration,
          easing: statisticsAnimationEasing,
          offset,
        },
        offset,
      )
      .add(
        {
          targets: `#customCircle--${id}`,
          easing: statisticsAnimationEasing,
          translateX: curvePath('x'),
          translateY: curvePath('y'),
          duration: curveAnimationDuration,
          begin: () => {
            // Here, we set setHasChartAnimationEnded to true,
            // so we must set it to false when the Statistics component
            // is destroyed (or, before a new calculation is run)
            // as the the curve would be calculated with extra width
            store.dispatch('setHasChartAnimationEnded', true);
          },
        },
        offset,
      )
      .add(
        {
          targets: '.side-label, .match-outcome',
          easing: statisticsAnimationEasing,
          opacity: [0, 1],
        },
        offset + curveAnimationDuration,
      )
      .add(
        {
          targets: '.area-under',
          opacity: [0, 1],
          easing: statisticsAnimationEasing,
        },
        offset + curveAnimationDuration,
      )
      // This isn't a part of the chart,
      // but it was the easiest to just chain here
      .add(
        {
          targets: '.average-goals-away, .average-goals-home',
          opacity: [0, 1],
          easing: statisticsAnimationEasing,
        }, offset + curveAnimationDuration,
      );
  }

  return waitForPromiseResolution(statisticsTimeline.finished);
};

const reverseAnimateChart = () => {
  const reverseChartTimeline = anime.timeline({
    easing: createCubicBezierString(reverseKeySplinesString(keySplines)),
    duration: curveAnimationDuration,
  });

  reverseChartTimeline.add({
    targets: '.rectangles, .chart, .side-label, .match-outcome, .average-goals-away, .average-goals-home',
    opacity: [1, 0],
    duration: curveAnimationDuration,
  });

  return waitForPromiseResolution(reverseChartTimeline.finished);
};

const animateResultsDetailsTimeline = dir => anime({
  direction: dir,
  targets: ['.results-details__timeline'],
  translateY: [50, 0],
  opacity: [0, 1],
  duration: 750,
  easing: 'easeInOutQuad',
});

const animateVideoFade = (dir) => {
  const resultsTimeline = anime.timeline({
    duration: 500,
    easing: 'easeInOutQuad',
    direction: dir,
  });
  resultsTimeline.add(
    {
      targets: '.vjs-custom-skin',
      opacity: [0, 1],
    },
    0,
  );
};

const animateWebVideoFade = (dir) => {
  const resultsTimeline = anime.timeline({
    duration: 500,
    easing: 'easeInOutQuad',
    direction: dir,
  });
  resultsTimeline.add(
    {
      targets: '.matches-wrapper',
      opacity: [0, 1],
    },
    0,
  );
};

export default {
  animateChart,
  animateCountdown,
  animateCountdownBar,
  animateMatchCurrentStat,
  animateMatchHighlights,
  animateMatchResult,
  animateMutualMatches,
  animatePreviousResults,
  animateResults,
  animateResultsDetailsTimeline,
  animateStatistics,
  animateSuperbonus,
  animateSuperbonusHistory,
  animateSuperbonusInfo,
  animateTeamShirt,
  animateVideoFade,
  animateWebVideoFade,
  reverseAnimateChart,
  reverseAnimateCountdown,
  superbonusFadeDuration,
};
