import { getApiRoute } from '@nsftx/games-visualization-sdk/config';
import http from './http';

export default {
  async check(appName) {
    const response = await http.post(`${getApiRoute('localAdmin')}:8082/publish`, {
      type: 'appAlive',
      data: {
        windowTitle: appName,
      },
    });
    return response.data;
  },
};
