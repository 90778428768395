import PathRenderer from './PathRenderer';
// *** MOTIVATION ***
// If you wanted to create a smooth curve that contains n arbitrary
// points in the plane, one approach you could take is to glue n-1
// cubic (cubic rather than quadratic - more control over smoothness) Bezier curves.
// To find such curves, you would need to compute their control points so that
// the final curve (the glued-up one) is smooth, or speaking in mathematical terms
// - twice differentiable everywhere. This module, and mainly the function
// computePathThroughKnots, finds those control points by implementing the Thomas'
// algorithm to solve a matrix that is key to the curve's smoothness.

function constructTargetVector(n, knots) {
  const result = new Array(n);
  result[0] = knots[0].plus(2, knots[1]);
  for (let i = 1; i < n - 1; i += 1) {
    result[i] = knots[i]
      .scaleBy(2)
      .plusPoint(knots[i + 1])
      .scaleBy(2);
  }
  result[result.length - 1] = knots[n - 1].scaleBy(8).plusPoint(knots[n]);
  return result;
}

function constructLowerDiagonalVector(length) {
  const result = new Array(length);
  result.fill(1);
  result[result.length - 1] = 2;
  return result;
}

function constructMainDiagonalVector(n) {
  const result = new Array(n);
  result.fill(4);
  result[0] = 2;
  result[result.length - 1] = 7;
  return result;
}

function constructUpperDiagonalVector(length) {
  const result = new Array(length);
  result.fill(1);
  return result;
}
function computeControlPoints(n, knots) {
  const result = new Array(2 * n);

  const target = constructTargetVector(n, knots);
  const lowerDiag = constructLowerDiagonalVector(n - 1);
  const mainDiag = constructMainDiagonalVector(n);
  const upperDiag = constructUpperDiagonalVector(n - 1);

  const newTarget = new Array(n);
  const newUpperDiag = new Array(n - 1);

  // forward sweep for control points c_i,0:
  newUpperDiag[0] = upperDiag[0] / mainDiag[0];
  newTarget[0] = target[0].scaleBy(1 / mainDiag[0]);

  for (let i = 1; i < n - 1; i += 1) {
    newUpperDiag[i] = upperDiag[i] / (mainDiag[i] - lowerDiag[i - 1] * newUpperDiag[i - 1]);
  }

  for (let i = 1; i < n; i += 1) {
    const targetScale = 1 / (mainDiag[i] - lowerDiag[i - 1] * newUpperDiag[i - 1]);

    newTarget[i] = target[i]
      .minusPoint(newTarget[i - 1].scaleBy(lowerDiag[i - 1]))
      .scaleBy(targetScale);
  }

  // backward sweep for control points c_i,0:
  result[n - 1] = newTarget[n - 1];

  for (let i = n - 2; i >= 0; i -= 1) {
    result[i] = newTarget[i].minus(newUpperDiag[i], result[i + 1]);
  }

  // calculate remaining control points c_i,1 directly:
  for (let i = 0; i < n - 1; i += 1) {
    result[n + i] = knots[i + 1].scaleBy(2).minusPoint(result[i + 1]);
  }

  result[2 * n - 1] = knots[n].plusPoint(result[n - 1]).scaleBy(0.5);

  return result;
}

function appendCurveToPath(path, control1, control2, targetKnot) {
  path.cubicTo(control1, control2, targetKnot);
}

export default function computePathThroughKnots(knots) {
  const polyBezierPath = new PathRenderer();
  const firstKnot = knots[0];
  polyBezierPath.moveTo(firstKnot);

  const n = knots.length - 1;

  if (n === 1) {
    const lastKnot = knots[1];
    polyBezierPath.lineTo(lastKnot.x, lastKnot.y);
  } else {
    const controlPoints = computeControlPoints(n, knots);

    for (let i = 0; i < n; i += 1) {
      const targetKnot = knots[i + 1];
      appendCurveToPath(polyBezierPath, controlPoints[i], controlPoints[n + i], targetKnot);
    }
  }

  return polyBezierPath;
}
