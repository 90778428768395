export default class Knot {
  constructor(value) {
    this.value = [...value];
  }

  getValue() {
    return this.value;
  }

  get x() {
    return this.value[1];
  }

  get y() {
    return this.value[0];
  }

  plus(factor, knot) {
    return new Knot([this.y + factor * knot.y, this.x + factor * knot.x]);
  }

  multiply(knot) {
    return new Knot([knot.y * this.y, knot.x * this.x]);
  }

  plusPoint(knot) {
    return this.plus(1, knot);
  }

  minus(factor, knot) {
    return new Knot([this.y - factor * knot.y, this.x - factor * knot.x]);
  }

  minusPoint(knot) {
    return this.minus(1, knot);
  }

  scaleBy(factor) {
    return new Knot([factor * this.y, factor * this.x]);
  }
}
