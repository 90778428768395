export default class PathRenderer {
  constructor(path = []) {
    this.value = path;
  }

  getValue() {
    return [...this.value];
  }

  moveTo(to) {
    this.value.push('M', to.getValue());
  }

  cubicTo(control1, control2, to) {
    this.value.push('C', control1.getValue(), control2.getValue(), to.getValue());
  }

  lineTo(to) {
    this.value.push('L', to.getValue());
  }

  finish(to) {
    this.value.push('T', to.getValue());
  }

  toD() {
    let d = '';

    this.value.forEach((value) => {
      if (typeof value === 'string') {
        d += value;
      } else if (Array.isArray(value)) {
        value.forEach((val) => {
          d += `${val} `;
        });
      }
    });

    return d;
  }

  cubicWithPrevTo(control, to) {
    this.value.push('S', control.getValue(), to.getValue());
  }
}
