export const hexToRgbA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const splitAndCapitalize = (str, splitBy) => {
  const words = str.toLowerCase().split(splitBy);

  for (let i = 0; i < words.length; i += 1) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }
  return words;
};

export const waitForPromiseResolution = promise => new Promise(async (resolve) => {
  await promise;
  resolve();
});

/**
 * Returns the function of a line defined by two points
 */
export const interpolate = ({ x1, y1 }, { x2, y2 }) => x => y1 + ((y2 - y1) * (x - x1)) / (x2 - x1);
