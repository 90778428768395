export default {
  config: {},
  urlParams: {},
  rules: {},
  product: {
    // temp data
    environment: 'staging',
    name: 'VirtualSoccer',
    platform: 'seven',
    deliveryPlatform: 'retail', // deliveryChannel
    productId: '866d2555-4e73-467e-bec7-aa3e15e3ab3e', // 'd0d13b68-dd11-4469-88fa-fff16d892f3e' channel or cpvUuid
    tenantId: '63d70140-bda0-4b42-b71b-723bf4b1c7f6', // 'b99752b3-443c-4c80-b559-945a95c4b805' company or companyUuid,
    locale: 'en',
  },
  activeGame: '',
  windowWidth: window?.innerWidth,
  gamesMapper: {
    virtualsoccerdev: 'VirtualSoccerDev',
    vsoccerdev: 'VirtualSoccerDev',
    VirtualSoccerDev: 'VirtualSoccerDev',
    virtualsoccer: 'VirtualSoccer',
    vsoccer: 'VirtualSoccer',
    VirtualSoccer: 'VirtualSoccer',
  },
  breakpoints: [
    { size: 'xs', maxWidth: 360 },
    { size: 'small', maxWidth: 784 },
    { size: 'normal', maxWidth: 1023 },
    { size: 'large', maxWidth: Infinity },
  ],
  // Our stats route returns a list
  statistics: [],
  hasChartAnimationEnded: false,
  isDesktopApp: false,
  desktopSystem: null,
  initializedGame: false,
  isOnline: true,
  connectionLostTimestamp: null,
  connectionLostInterval: null,
  connectionLostDuration: 0,
  connectionOfflineLimit: 30,
  connectionAlive: false,
  gameResponsive: true,
  isHalfTime: false,
  linodeEncodedWebStorage:
    'https://eu-central-1.linodeobjects.com/games-encoded-storage/games-videos/',
  halfTimeDuration: 5,
  countdownPhaseDuration: 15,
  statisticsPhaseDuration: 12,
  // Duration of the entire phase on mobile
  mobileStatisticsPhaseDuration: 20,
  // How long does the animation last when
  // all the child components are shown at once
  statisticsAnimationDuration: 3,
  countdownAnimationDuration: 3,
  event: {
    currentEvent: '', // countdown, video, jackpotwon, results
    offer: {},
    events: [],
    results: {},
    previousResults: [],
    // All the videos in one array
    allVideos: [],
    jackpotInfoAmount: 0,
    jackpotWonData: {},
    jackpotWonFinished: false,
    video: [],
    videoGoalList: {},
    videoResult: {
      home: 0,
      away: 0,
    },
    videoFinalResult: {
      home: 0,
      away: 0,
    },
    videoFinished: false,
    videoId: '',
    videoExt: 'webm',
    stateData: undefined,
    stateMode: true,
    countdownTime: 0,
    countdownDelay: 0,
    eventId: 0,
  },
  // We need this prop because when we start the application
  // mid match, we want to show the countdown, without stretching ('dropping')
  // the header to full screen, and also for the next startCountdown msg
  // after that message is received, we want to stop dropping
  shouldDropHeaderBeforeCountdown: true,
  // We need this prop because when we start the application
  // mid match, we want to show the countdown, without rotating
  // between the countdown and the statistics, before the next
  // startCountdown message, and the rotation starts within the
  // handleStartCountdown action
  shouldRotateEvents: true,
  countdownEventData: {},
  defaultHealthCheckerTime: 30,
  healthCheckerTime: 6,
  stopBettingDuration: 5,
  resultsDuration: 21,
  ignoreSocketMessages: false,
  loader: {
    isLoaderActive: true,
    isLastCountdown: false,
    countdownTime: 0,
    progressStartValue: 0,
    progressFullWidth: true,
    clientLogo: false,
  },
  eventInfo: {
    active: false,
    text: '',
  },
  nextEventInfo: {
    active: false,
    text: '',
  },
  displaySettings: {
    devAssets: false, // force local assets (public/assets/), development only
    assetsPreloaded: false,
    assetsFolder: 'virtualsoccer-videos-standard',
    forceExternalAssets: false, // if local assets and/or service don't exist
  },
  jackpotActive: false,
  numberFormat: 'european',
  assetsSubsetReady: false,
  translations: {},
  audioOn: false,
  // Bus service init state
  busServiceInitialized: false,
};
