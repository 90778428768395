import {
  forEach,
  omit,
  find,
  merge,
  filter,
} from 'lodash';
import { formatNumber } from '@nsftx/games-visualization-sdk/src/utility';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

/**
 * Parser for countdown component, function returning combination of events and offers.
 * @param {Object[]} offers - object containing marketId, marketType, outcomes
 * @param {Object[]} events - object containing competitors, eventId, eventIdToday, odds, timestamp
 */
const countdownParser = (offers, events) => {
  const eventMarketOutcomes = [];
  const eventOffers = {};
  forEach(offers, (offer) => {
    eventOffers[offer.marketType] = {
      marketId: offer.marketId,
      marketType: offer.marketType,
      odds: [],
    };
  });
  forEach(events, (event, index) => {
    eventMarketOutcomes.push(merge(omit(event, ['odds']), {
      eventOffers,
    }));
    forEach(event.odds, (odd) => {
      const eventMarket = find(offers, ['marketId', odd.marketId]);
      const eventMarketOutcome = filter(eventMarket.outcomes, ['outcomeId', odd.outcomeId]);
      if (odd.marketId === 1) {
        // Get fullTime odds and push them into odds array for fullTime
        eventMarketOutcomes[index].eventOffers.fullTime.odds.push({
          outcomeType: eventMarketOutcome[0].outcomeType,
          odd: formatNumber(odd.value, 2, false, store.getters.numberFormat),
        });
      } else if (odd.marketId === 2) {
        // Get halfTime odds and push them into odds array for halfTime
        eventMarketOutcomes[index].eventOffers.halfTime.odds.push({
          outcomeType: eventMarketOutcome[0].outcomeType,
          odd: formatNumber(odd.value, 2, false, store.getters.numberFormat),
        });
      } else if (odd.marketId === 3) {
        // Get correctScore odds and push them into odds array for correctScore
        eventMarketOutcomes[index].eventOffers.correctScore.odds.push({
          outcomeType: eventMarketOutcome[0].outcomeType,
          odd: formatNumber(odd.value, 2, false, store.getters.numberFormat),
        });
      } else if (odd.marketId >= 4) {
        // Get totalGoals odds and push them into odds array for totalGoals
        eventMarketOutcomes[index].eventOffers.totalGoals.odds.push({
          outcomeType: eventMarketOutcome[0].outcomeType,
          odd: formatNumber(odd.value, 2, false, store.getters.numberFormat),
          limitValue: eventMarket.limitValue,
        });
      }
    });
  });
  return eventMarketOutcomes;
};

export default {
  countdownParser,
};
