export default {
  SET_URL_PARAMS: 'SET_URL_PARAMS',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_CONFIG: 'SET_CONFIG',
  SET_RULES: 'SET_RULES',
  SET_COUNTDOWN_EVENT_DATA: 'SET_COUNTDOWN_EVENT_DATA',
  SET_LOADER: 'SET_LOADER',
  SET_EVENT_ID: 'SET_EVENT_ID',
  SET_EVENT_INFO: 'SET_EVENT_INFO',
  SET_NEXT_EVENT_INFO: 'SET_NEXT_EVENT_INFO',
  SET_RESULTS: 'SET_RESULTS',
  SET_PREVIOUS_RESULTS: 'SET_PREVIOUS_RESULTS',
  SET_JACKPOT: 'SET_JACKPOT',
  SET_JACKPOTWON_DATA: 'SET_JACKPOTWON_DATA',
  SET_JACKPOTWON_FINISHED: 'SET_JACKPOTWON_FINISHED',
  SET_OFFER: 'SET_OFFER',
  SET_EVENT_MARKET_OUTCOMES: 'SET_EVENT_MARKET_OUTCOMES',
  SET_COUNTDOWN_TIME: 'SET_COUNTDOWN_TIME',
  SET_SHOULD_DROP_HEADER_BEFORE_COUNTDOWN: 'SET_SHOULD_DROP_HEADER_BEFORE_COUNTDOWN',
  SET_SHOULD_ROTATE_EVENTS: 'SET_SHOULD_ROTATE_EVENTS',
  SET_COUNTDOWN_DELAY: 'SET_COUNTDOWN_DELAY',
  SET_VIDEO: 'SET_VIDEO',
  SET_VIDEO_GOAL_LIST: 'SET_VIDEO_GOAL_LIST',
  SET_VIDEO_RESULT: 'SET_VIDEO_RESULT',
  SET_VIDEO_FINAL_RESULT: 'SET_VIDEO_FINAL_RESULT',
  SET_IGNORE_SOCKET_MESSAGES: 'SET_IGNORE_SOCKET_MESSAGES',
  SET_VIDEO_FINISHED: 'SET_VIDEO_FINISHED',
  SET_VIDEO_PATH: 'SET_VIDEO_PATH',
  SET_ALL_VIDEOS: 'SET_ALL_VIDEOS',
  SET_CURRENT_EVENT: 'SET_CURRENT_EVENT',
  SET_BUS_INIT_STATE: 'SET_BUS_INIT_STATE',
  SET_STATE_MODE: 'SET_STATE_MODE',
  SET_STATE_DATA: 'SET_STATE_DATA',
  SET_STOP_BETTING_DURATION: 'SET_STOP_BETTING_DURATION',
  RESET_RESULT: 'RESET_RESULT',
  SET_DESKTOP_APP_DETAILS: 'SET_DESKTOP_APP_DETAILS',
  SET_DISPLAY_SETTINGS: 'SET_DISPLAY_SETTINGS',
  SET_JACKPOT_ACTIVITY: 'SET_JACKPOT_ACTIVITY',
  SET_ONLINE_STATUS: 'SET_ONLINE_STATUS',
  SET_CONNECTION_LOST_TIME: 'SET_CONNECTION_LOST_TIME',
  RESET_CONNECTION_LOST_TIME: 'RESET_CONNECTION_LOST_TIME',
  INITIALIZE_GAME: 'INITIALIZE_GAME',
  SET_ASSETS_READY: 'SET_ASSETS_READY',
  SET_ASSETS_SUBSET_READY: 'SET_ASSETS_SUBSET_READY',
  SET_ACTIVE_GAME: 'SET_ACTIVE_GAME',
  SET_GAME_RESPONSIVENESS: 'SET_GAME_RESPONSIVENESS',
  SET_HEALTH_CHECKER_TIME: 'SET_HEALTH_CHECKER_TIME',
  SET_HEALTH_CHECKER: 'SET_HEALTH_CHECKER',
  SET_IS_HALF_TIME: 'SET_IS_HALF_TIME',
  SET_WINDOW_WIDTH: 'SET_WINDOW_WIDTH',
  SET_STATISTICS: 'SET_STATISTICS',
  SET_HAS_CHART_ANIMATION_ENDED: 'SET_HAS_CHART_ANIMATION_ENDED',
  TOGGLE_AUDIO: 'TOGGLE_AUDIO',
};
