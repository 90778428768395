import { merge, each, isNil } from 'lodash';
import types from './mutationTypes';

export default {
  [types.SET_URL_PARAMS](state, payload) {
    state.urlParams = payload;
  },
  [types.SET_TRANSLATIONS](state, payload) {
    state.translations = merge({}, state.translations, payload);
  },
  [types.SET_CONFIG](state, payload) {
    state.config = payload;
  },
  [types.SET_RULES](state, payload) {
    state.rules = payload;
  },
  [types.SET_LOADER](state, payload) {
    state.loader.isLoaderActive = payload;
  },
  [types.SET_EVENT_ID](state, payload) {
    state.event.eventId = payload;
  },
  [types.SET_STATISTICS](state, payload) {
    state.statistics = payload;
  },
  [types.SET_EVENT_INFO](state, payload) {
    state.eventInfo.active = payload;
  },
  [types.SET_NEXT_EVENT_INFO](state, payload) {
    state.nextEventInfo.active = payload;
  },
  [types.SET_RESULTS](state, payload) {
    state.event.results = payload;
  },
  [types.SET_PREVIOUS_RESULTS](state, payload) {
    state.event.previousResults = payload;
  },
  [types.SET_JACKPOT](state, payload) {
    state.event.jackpotInfoAmount = payload;
  },
  [types.SET_JACKPOTWON_DATA](state, payload) {
    state.event.jackpotWonData = payload;
  },
  [types.SET_JACKPOTWON_FINISHED](state, payload) {
    state.event.jackpotWonFinished = payload;
  },
  [types.SET_OFFER](state, payload) {
    state.event.offer = payload;
  },
  [types.SET_IS_HALF_TIME](state, payload) {
    state.isHalfTime = payload;
  },
  [types.SET_WINDOW_WIDTH](state, payload) {
    state.windowWidth = payload;
  },
  [types.SET_EVENT_MARKET_OUTCOMES](state, payload) {
    state.event.events = payload;
  },
  [types.SET_COUNTDOWN_TIME](state, payload) {
    state.event.countdownTime = payload;
  },
  [types.SET_COUNTDOWN_EVENT_DATA](state, payload) {
    state.countdownEventData = payload;
  },
  [types.SET_SHOULD_DROP_HEADER_BEFORE_COUNTDOWN](state, payload) {
    state.shouldDropHeaderBeforeCountdown = payload;
  },
  [types.SET_SHOULD_ROTATE_EVENTS](state, payload) {
    state.shouldRotateEvents = payload;
  },
  [types.SET_COUNTDOWN_DELAY](state, payload) {
    state.event.countdownDelay = payload;
  },
  [types.SET_VIDEO](state, payload) {
    state.event.video = payload;
  },
  [types.SET_VIDEO_GOAL_LIST](state, payload) {
    state.event.videoGoalList = payload;
  },
  [types.SET_VIDEO_RESULT](state, payload) {
    state.event.videoResult = payload;
  },
  [types.SET_VIDEO_FINAL_RESULT](state, payload) {
    state.event.videoFinalResult = payload;
  },
  [types.SET_VIDEO_FINISHED](state, payload) {
    state.event.videoFinished = payload;
  },
  [types.SET_VIDEO_PATH](state, payload) {
    state.event.videoId = payload;
  },
  [types.SET_ALL_VIDEOS](state, payload) {
    state.event.allVideos = payload;
  },
  [types.SET_IGNORE_SOCKET_MESSAGES](state, payload) {
    state.ignoreSocketMessages = payload;
  },
  [types.SET_CURRENT_EVENT](state, payload) {
    state.event.currentEvent = payload;
  },
  [types.SET_STATE_MODE](state, payload) {
    state.event.stateMode = payload;
  },
  [types.SET_STATE_DATA](state, payload) {
    state.event.stateData = payload;
  },
  [types.INITIALIZE_GAME](state, payload) {
    state.initializedGame = payload;
  },
  [types.SET_BUS_INIT_STATE](state, payload = true) {
    state.busServiceInitialized = payload;
  },
  [types.SET_ASSETS_SUBSET_READY](state, payload) {
    state.assetsSubsetReady = payload;
  },
  [types.SET_STOP_BETTING_DURATION](state, payload) {
    state.stopBettingDuration = payload;
  },
  [types.SET_HAS_CHART_ANIMATION_ENDED](state, payload) {
    state.hasChartAnimationEnded = payload;
  },
  [types.RESET_RESULT](state) {
    const result = {
      home: 0,
      away: 0,
    };
    state.event.videoResult = result;
    state.event.videoFinalResult = result;
    state.event.videoGoalList = {};
  },
  [types.SET_DESKTOP_APP_DETAILS](state, payload) {
    state.isDesktopApp = payload.isDesktopApp;
    state.desktopSystem = payload.desktopSystem !== 'undefined' ? payload.desktopSystem : null;
  },
  [types.SET_DISPLAY_SETTINGS](state, payload) {
    each(payload, (value, key) => {
      state.displaySettings[key] = value;
    });
  },
  [types.SET_JACKPOT_ACTIVITY](state, payload) {
    state.jackpotActive = payload;
  },
  [types.SET_ONLINE_STATUS](state, payload) {
    state.isOnline = payload;
  },
  [types.SET_CONNECTION_LOST_TIME](state) {
    if (isNil(state.connectionLostTimestamp)) {
      state.connectionLostTimestamp = new Date().getTime();
      state.connectionLostDuration = 0;
      state.connectionLostInterval = setInterval(() => {
        const oldTime = (state.connectionLostTimestamp || new Date().getTime()) / 1000;
        const newTime = new Date().getTime() / 1000;

        state.connectionLostDuration = newTime - oldTime;
      }, 1000);
    }
  },
  [types.RESET_CONNECTION_LOST_TIME](state) {
    clearInterval(state.connectionLostInterval);
    state.connectionLostInterval = null;
    state.connectionLostTimestamp = null;
    state.connectionLostDuration = 0;
  },
  [types.SET_ACTIVE_GAME](state, payload) {
    state.activeGame = payload;
  },
  [types.SET_GAME_RESPONSIVENESS](state, value) {
    state.gameResponsive = value;
  },
  [types.SET_HEALTH_CHECKER](state, value) {
    state.connectionAlive = value;
  },
  [types.SET_HEALTH_CHECKER_TIME](state, value) {
    state.healthCheckerTime = value;
  },
  [types.TOGGLE_AUDIO](state, value) {
    state.audioOn = value;
  },
};
