const halfPartTypes = {
  FIRST: 'FIRST',
  SECOND: 'SECOND',
};

const actionTypes = {
  GOAL: 'GOAL',
  CHANCE: 'CHANCE',
};
/**
 *
 * @param {*} store - relevant store instance
 * @param {*} mutationTypes
 * @param {*} options - everything else
 * @returns
 */
function createUtility({ commit, dispatch, getters }, mutationTypes, options) {
  /**
   * We should provide this function with the duration of the first
   * half of the game.
   * @param {number} duration
   * @returns
   */
  function handleHalfTime(duration) {
    const { halfTimeDuration } = getters;

    setTimeout(() => {
      dispatch('setIsHalfTime', true);
      setTimeout(() => {
        dispatch('setIsHalfTime', false);
      }, halfTimeDuration * 1000);
    }, duration * 1000);

    return duration + halfTimeDuration;
  }

  function isGoal(video) {
    return video.actionType === actionTypes.GOAL;
  }

  /**
   * Sets the path property to the current's video path
   * Updates the score state after the video had finished
   * @param {*} video
   * @param {number} duration
   */
  function setVideo(video, duration) {
    setTimeout(() => {
      if (isGoal(video)) {
        // Set the results after the video had finished
        setTimeout(() => {
          commit(mutationTypes.SET_VIDEO_RESULT, {
            home: video.competitors[0].fullTime,
            away: video.competitors[1].fullTime,
          });
        }, video.duration * 1000);
      }
      commit(mutationTypes.SET_VIDEO_PATH, video.name);
      commit(mutationTypes.SET_VIDEO, video);
    }, duration * 1000);
  }

  /**
   * The duration is accumulated as each video is played
   * (by setting its src into the sdk component; see Match.vue)
   * after the summed durations of the preceding videos.
   */
  function showHalfPart(videos, duration) {
    let newDuration = duration;

    videos.forEach((video) => {
      setVideo(video, newDuration);
      newDuration += video.duration;
    });

    return newDuration;
  }

  function setFinalResultsAndGoalList(videos, goalList) {
    const {
      utility: { getVideoData },
    } = options;

    videos.forEach((video, index) => {
      if (isGoal(video)) {
        const videoData = getVideoData(videos, video, index, goalList);
        commit(mutationTypes.SET_VIDEO_FINAL_RESULT, {
          home: videoData.homeResult,
          away: videoData.awayResult,
        });
        commit(mutationTypes.SET_VIDEO_GOAL_LIST, videoData.goalList);
      }
    });
  }

  function handleHalfPart(videos, duration) {
    const newDuration = showHalfPart(videos, duration);

    return newDuration;
  }

  function splitHalfParts(videos) {
    const firstHalfVideos = videos.filter(video => video.halfPart === halfPartTypes.FIRST);
    const secondHalfVideos = videos.filter(video => video.halfPart === halfPartTypes.SECOND);

    return { firstHalfVideos, secondHalfVideos };
  }

  return {
    handleHalfPart,
    handleHalfTime,
    splitHalfParts,
    setFinalResultsAndGoalList,
  };
}

export default createUtility;
