const reverseCubicBezier = (controlPoints) => {
  // Extract the control points
  const [x1, y1, x2, y2] = controlPoints;

  // Calculate the reverse control points
  const reverseControlPoints = [
    1 - x2, // Reverse x2
    1 - y2, // Reverse y2
    1 - x1, // Reverse x1
    1 - y1, // Reverse y1
  ];

  // Return the reverse control points
  return reverseControlPoints;
};

/**
 * Receives a flattened array of control points
 * @param {Array} arr
 */
const flattenedControlPointsToString = flatPoints => flatPoints.join(',');

const reverseKeySplinesString = (keySplines) => {
  const flattenedOriginalSplines = keySplines.split(' ').map(val => Number(val));
  const reversedSplines = reverseCubicBezier(flattenedOriginalSplines);
  const reversedAndTrimmedSplines = reversedSplines.map(spline => Number(spline.toFixed(2)));
  return flattenedControlPointsToString(reversedAndTrimmedSplines);
};
const createCubicBezierString = keySplines => `cubicBezier(${keySplines.split(' ').join(', ')})`;


export {
  reverseCubicBezier,
  flattenedControlPointsToString,
  reverseKeySplinesString,
  createCubicBezierString,
};
