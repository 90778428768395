const getCountdownTime = (time) => {
  const eventStart = new Date(time).getTime();
  const now = new Date();
  const utcNow = now.getTime();
  return Math.floor((eventStart - utcNow) / 1000);
};
const getCountdownDelay = (time) => {
  const eventStart = new Date(time).getTime();
  const now = new Date();
  const utcNow = now.getTime();
  return Math.floor((utcNow - eventStart) / 1000);
};

export default {
  getCountdownTime,
  getCountdownDelay,
};
