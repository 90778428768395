import Vue from 'vue';
import { sdkModule } from '@nsftx/games-visualization-sdk/src/store/modules';
import { sentry } from '@/utility';
import App from './App';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

if (process.env.VUE_APP_SENTRY_ACTIVE === 'true') {
  sentry.start(Vue);
}

new Vue({
  router,
  store,
  created() {
    store.dispatch('setDesktopAppDetails');
    if (!store.hasModule('sdk')) {
      store.registerModule('sdk', sdkModule);
    }
  },
  render: h => h(App),
}).$mount('#app');
