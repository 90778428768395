<template>
  <router-view></router-view>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '@/utility';

export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'getTranslation',
      'isLongerOffline',
      'stateData',
    ]),
  },
  methods: {
    ...mapActions([
      'updateOnlineStatus',
      'handleConfigState',
      'setWindowWidth',
      'setLoader',
      'handleConfigProperties',
      'handleState',
      'setDefaultHealthChecker',
    ]),
    ...mapActions('sdk', [
      'setInfoMessage',
    ]),
    setListeners() {
      eventBus.$on('getConfigState', () => {
        this.handleConfigState(this.$config);
      });
    },
    handleResize: debounce(function setWindowWidth() {
      this.setWindowWidth(window.innerWidth);
    }, 150),
  },
  watch: {
    async isLongerOffline(newValue) {
      if (newValue === true) {
        this.setInfoMessage(this.getTranslation('disconnected'));
        this.setLoader(true);
      } else {
        this.setInfoMessage('');
        this.setLoader(false);
        /**
         * Update config on reconnect
         */
        this.handleConfigState(this.$config);
        // const config = await this.$config.getConfiguration();
        // this.handleConfigProperties(config);
        // this.handleState(config.state.gameState);
      }
    },
  },
  mounted() {
    this.updateOnlineStatus();
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    window.addEventListener('resize', this.handleResize);

    this.setListeners();
  },
  onUnmounted() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    window.removeEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    eventBus.$off('getConfigState');
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: primary-font, Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba($bg-color-primary, .8);
}
</style>
